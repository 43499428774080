@tailwind base;
@tailwind components;
@tailwind utilities; 

@font-face {
  font-family: "PathwayGothicOne";
  src: url("../public/fonts/PathwayGothicOne-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Regular.ttf");
}